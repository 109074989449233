import request from '../../api/request.ts'
import { inclineFirstname, inclineLastname } from 'lvovich';

function escapeHtml(unsafe) {
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

export class Previewer {
    constructor(previewNode, templateId, kind, client) {
        this.previewNode = previewNode
        this.templateId = templateId
        this.kind = kind
        this.client = client
        this.variables = {}
    }

    prepare() {
        const formData = new FormData()
        formData.append('template', this.templateId)
        if (this.kind) {
            formData.append('kind', this.kind)
        }
        if (this.client) {
            formData.append('client', this.client)
        }
        return request('getTemplateHtml', formData)
            .then(data => {
                this.previewNode.innerHTML = data.html
                this.previewNode.scrollTo(0, 0)

                this.variables = this.parseVariables(this.previewNode)
                return Object.keys(this.variables)
            })
    }

    parseVariables(finishedDocument) {
        const variables = {}
        finishedDocument.querySelectorAll('.variable').forEach(node => {
            const variableId = node.dataset.variableId
            variables[variableId] = variables[variableId] || []
            variables[variableId].push(node)
        })
        return variables
    }

    fill(values) {
        const preparedValues = this.prepareValues(values)

        Object.keys(this.variables).forEach(variableId => {
            const value = preparedValues[variableId] || ''
            this.variables[variableId].forEach(variableNode => {
                variableNode.innerHTML = escapeHtml(value)
            })
        })
    }

    prepareValues(values) {
        if (values['director.fio']) {
            const parts = values['director.fio'].split(' ')
            const letter1 = parts[1] ? parts[1].substring(0, 1) : ''
            const letter2 = parts[2] ? parts[2].substring(0, 1) : ''
            const letters = (letter1 ? letter1 + '.' : '') + (letter2 ? letter2 + '.' : '')
            values['director.name'] = (letters ? letters + ' ' : '') + parts[0]

            values['director.name_im_p'] = parts[0] + (letters ? ' ' + letters : '')
            values['director.name_dat_p'] = inclineLastname(parts[0], 'dative') + (letters ? ' ' + letters : '')
            values['director.name_rod_p'] = inclineLastname(parts[0], 'genitive') + (letters ? ' ' + letters : '')
        }
        if (values['director.position']) {
            values['director.position_dat_p'] = inclineFirstname(values['director.position'], 'dative')
            values['director.position_rod_p'] = inclineFirstname(values['director.position'], 'genitive')
        }
        if (values['date']) {
            if (/\d{4}-\d{2}-\d{2}/.test(values['date'])) {
                const parts = values['date'].split('-')
                const date = new Date(values['date'])
                const dateFormatterOptions = { year: 'numeric', month: 'long', day: '2-digit' }
                const dateFormatter = new Intl.DateTimeFormat('ru', dateFormatterOptions)
                values['date'] = dateFormatter.format(date).replace(' г.', '')
                values['date_numeric'] = parts[2] + '.' + parts[1] + '.' + parts[0]
            }
        }
        if (!values['license']) {
            values['license'] = 'Лицензия на осуществление образовательной деятельности №___ от ______ выданная ______________, серия бланка ______, номер бланка _______'
            values['license_pred_p'] = 'лицензии на осуществление образовательной деятельности №___ от ______, выданной ______________, серия бланка _____, номер бланка _____'
        } else {
            values['license_pred_p'] = values['license'].replace('Лицензия', 'лицензии').replace('лицензия', 'лицензии')
        }
        return values
    }
}
