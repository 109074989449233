import { defaultValues } from './defaultValues'
import { Previewer } from './previewer'
import request from '../../api/request'
import { reachGoal } from '../analytics'

const initConstructor = function (containerEl) {
  const documentSelectionSectionEl = containerEl.querySelector('#document-selection')
  const createDocumentSectionEl = containerEl.querySelector('#create-document')
  const templateFillSectionEl = containerEl.querySelector('#template-fill')

  const typeBreadcrumbEl = containerEl.querySelector('[data-breadcrumbs-type]')
  const titleBreadcrumbEl = containerEl.querySelector('[data-breadcrumbs-document]')

  const openConstructorButtonsEls = document.body.querySelectorAll('[data-constructor-open]')
  openConstructorButtonsEls.forEach(openConstructorButtonEl => {
    openConstructorButtonEl.addEventListener('click', function (evt) {
      evt.preventDefault()
      scrollToSection(documentSelectionSectionEl)
    })
  })

  const formKindWrapperEl = createDocumentSectionEl.querySelector('[data-form-kind]')
  const formClientWrapperEl = createDocumentSectionEl.querySelector('[data-form-entity]')
  const formVariablesWrapperEl = createDocumentSectionEl.querySelector('[data-form-variables]')

  let previewer

  const fields = Array.from(formVariablesWrapperEl.querySelectorAll('[data-field-id]'))
  const inputs = formVariablesWrapperEl.querySelectorAll('[name^=variables]')
  const nameToId = name => name.replace('variables[', '', '').replaceAll('[', '.').replaceAll(']', '')
  const values = Object.assign({}, defaultValues)
  inputs.forEach(input => {
    const applyValue = () => {
      const variableId = nameToId(input.name)
      values[variableId] = input.value || defaultValues[variableId]
      if (previewer) {
        previewer.fill(values)
      }
    }

    input.addEventListener('keyup', applyValue)
    input.addEventListener('change', applyValue)
  })

  const finishedDocumentEl = containerEl.querySelector('#finished-document')

  let kind = 'any'
  let client = 'any'
  let initPreviewer

  const kindOptionsEls = formKindWrapperEl.querySelectorAll('input')
  kindOptionsEls.forEach((option) => {
    option.addEventListener('change', (evt) => {
      kind = evt.target.value
      if (initPreviewer) {
        initPreviewer()
      }
    })
  })

  const clientOptions1Els = formClientWrapperEl.querySelectorAll('input')
  clientOptions1Els.forEach((option) => {
    option.addEventListener('change', (evt) => {
      client = evt.target.value
      if (initPreviewer) {
        initPreviewer()
      }
    })
  })

  const showTemplate = function (templateId) {
    templateId = parseInt(templateId)
    const template = window.port.templates.find(template => template.id === templateId)
    if (!template) {
      console.error(`Шаблон ${templateId} не найден.`)
      return
    }

    typeBreadcrumbEl.innerHTML = template.type.title
    titleBreadcrumbEl.innerHTML = template.title
    titleBreadcrumbEl.setAttribute('title', template.title)

    createDocumentSectionEl.dataset.id = template.id

    if (template.kindSensitive) {
      formKindWrapperEl.hidden = false
      kind = formKindWrapperEl.querySelector('input:checked').value
    } else {
      formKindWrapperEl.hidden = true
      kind = 'any'
    }

    if (template.clientSensitive) {
      formClientWrapperEl.hidden = false
      client = formClientWrapperEl.querySelector('input:checked').value
    } else {
      formClientWrapperEl.hidden = true
      client = 'any'
    }

    fields.forEach(field => {
      field.hidden = true
    })

    initPreviewer = () => {
      previewer = new Previewer(finishedDocumentEl, template.id, kind, client)
      previewer
        .prepare()
        .then(variablesIds => {
          const relativeVariablesIds = {
            'director.name': 'director.fio',
            'director.name_im_p': 'director.fio',
            'director.name_dat_p': 'director.fio',
            'director.name_rod_p': 'director.fio',
            'director.position_rod_p': 'director.position',
            'director.position_dat_p': 'director.position',
            'license_pred_p': 'license',
          }

          Object.keys(relativeVariablesIds).forEach(templateVariableId => {
            const fieldVariableId = relativeVariablesIds[templateVariableId]
            if (variablesIds.includes(templateVariableId)) {
              if (!variablesIds.includes(fieldVariableId)) {
                variablesIds.splice(variablesIds.indexOf(templateVariableId), 0, fieldVariableId)
              } else {
                const index1 = variablesIds.indexOf(fieldVariableId)
                const index2 = variablesIds.indexOf(templateVariableId)
                if (index1 > index2) {
                  delete variablesIds[index1]
                  variablesIds.splice(variablesIds.indexOf(templateVariableId), 0, fieldVariableId)
                }
              }
            }
          })

          fields.forEach(field => {
            if (variablesIds.includes(field.dataset.fieldId)) {
              field.hidden = false
            }
          })

          sortFieldsByVariablesPositions(fields, variablesIds)

          fields[0].querySelector('[name]').focus()

          previewer.fill(values)
        })
    }
    initPreviewer()

    scrollToSection(createDocumentSectionEl, template.id)
  }

  // Карточки типов документов (положения, приказы, правила и т.п.) со списками шаблонов.
  const typesCardsEls = documentSelectionSectionEl.querySelectorAll('[data-document-type]')
  typesCardsEls.forEach(typeCardEl => {
    const templatesListWrapperEl = typeCardEl.querySelector('[data-documents-list]')

    // Кнопки с названиями шаблонов.
    const templatesButtonsEls = templatesListWrapperEl.querySelectorAll('button')
    templatesButtonsEls.forEach(templateButtonEl => {
      templateButtonEl.addEventListener('click', () => {
        const templateId = parseInt(templateButtonEl.dataset.templateId)
        showTemplate(templateId)
      })
    })

    // Кнопка Показать все.
    const showAllButtonEl = typeCardEl.querySelector('.card__actions button')
    if (showAllButtonEl) {
      const templatesListEls = templatesListWrapperEl.querySelectorAll('li')

      showAllButtonEl.addEventListener('click', () => {
        templatesListEls.forEach(templateLiEl => {
          templateLiEl.hidden = false
        })
        showAllButtonEl.hidden = true
      })
    }
  })

  containerEl.querySelectorAll('.search-documents-result [data-template-id]').forEach(buttonEl => {
    buttonEl.addEventListener('click', function () {
      showTemplate(buttonEl.dataset.templateId)
    })
  })

  changeSection()

  document.body.querySelector('#all-templates-variables').addEventListener('submit', (e) => {
    e.preventDefault()
  })

  initLockTextCopy()
  initSendDocumentToMail()
  initErrorFormSubmit()
  initSearchDocuments(containerEl)
  initPrintDocument(containerEl)
  initFullScreen(containerEl)
}

const changeSection = () => {
  const hash = window.location.hash.split('/')
  if (hash.length > 1) {
    const button = document.body.querySelector(`.document-selection button[data-template-id="${hash[1]}"]`)

    if (button) {
      button.click()
    }
  } else {
    const sectionEl = document.getElementById(hash[0].replace('#', ''))
    if (sectionEl) {
      scrollToSection(sectionEl)
    }
  }
}

/* Инициализация поисковика документов */
function initSearchDocuments (containerEl) {
  const section = containerEl.querySelector('#document-selection')
  const input = section.querySelector('#search-documents')
  const result = section.querySelector('.search-documents-result')
  const notFound = section.querySelector('.search-not-found')
  const resultList = section.querySelectorAll('.search-documents-result li')

  function searchDocument () {
    const value = input.value.toLowerCase()
    let length = 0
    result.hidden = true
    if (value.trim().length > 2) {
      resultList.forEach(item => {
        if (item.innerText.toLowerCase().includes(value)) {
          item.hidden = false
          result.hidden = false
          length++
        } else {
          item.hidden = true
        }
      })
      if (length === 0) {
        notFound.hidden = false
      }
    } else {
      notFound.hidden = true
    }
  }

  input.addEventListener('input', searchDocument)

  document.addEventListener('click', evt => {
    if (!evt.target.closest('.search-documents-form__field') && !evt.target.closest('.search-documents-result')) {
      input.value = ''
      searchDocument()
    }
  })
}

/* Инициализация кнопки печати документа */
function initPrintDocument (containerEl) {
  const button = containerEl.querySelector('#print-document')

  button.addEventListener('click', () => {
    const printText = document.getElementById('finished-document').innerHTML
    const printCSS = '<link rel="stylesheet" href="/assets/print.css" type="text/css" />'
    const windowPrint = window.open('', '', 'left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0')
    windowPrint.document.write(printCSS)
    windowPrint.document.write(printText)
    windowPrint.document.close()

    windowPrint.addEventListener('load', () => {
      windowPrint.focus()
      windowPrint.print()
      windowPrint.close()
    })
  })
}

/* Инициализация формы об ошибке в документе */
function initErrorFormSubmit () {
  const form = document.body.querySelector('#error_form')
  form.addEventListener('submit', evt => {
    evt.preventDefault()
    const formData = new FormData(form)
    formData.set('template', document.body.querySelector('#create-document').dataset.id)

    request('documents.reportError', formData).then(() => {
      form.querySelector('[data-element="body"]').hidden = true
      form.querySelector('[data-element="successMessage"]').hidden = false
    })
  })
}

/* Инициализация формы 'Получить документ' */
function initSendDocumentToMail () {
  const modalForms = []
  modalForms.push(document.body.querySelector('#get-document_form'))
  modalForms.push(document.body.querySelector('#buy-all_form'))

  modalForms.forEach(modalForm => {
    const submit = modalForm.querySelector('[type="submit"]')
    modalForm.addEventListener('submit', evt => {
      evt.preventDefault()

      const isAll = modalForm.id === 'buy-all_form'

      const form = document.body.querySelector(isAll ? '#all-templates-variables' : '#template-variables')
      const formData = new FormData(form)
      const modalFormData = new FormData(modalForm)

      formData.append('template', isAll ? 'all' : document.body.querySelector('#create-document').dataset.id)
      formData.append('name', modalFormData.get('name'))
      formData.append('email', modalFormData.get('email'))
      formData.append('phone', modalFormData.get('phone'))

      modalForm.querySelectorAll('.format-type input').forEach(input => {
        if (input.checked) {
          formData.append('formats[]', input.value)
        }
      })

      submit.disabled = true
      request('createTemplatesOrder', formData)
        .then(data => {
          modalForm.querySelector('[data-element="body"]').hidden = true
          modalForm.querySelector('[data-element="successMessage"]').hidden = false

          const goal = isAll ? 'package' : 'document'
          reachGoal(goal)
        })
        .finally(() => {
          submit.disabled = false
        })
    })
  })
}

// Попытаемся запретить копированиие документа
function initLockTextCopy () {
  document.body.querySelectorAll('.no-select').forEach(section => {
    section.oncopy = () => { return false }
  })
}

const scrollToSection = (section, id) => {
  document.body.querySelectorAll('[data-step]').forEach(section => section.hidden = true)
  section.hidden = false
  window.location.hash = section.id + (id ? ('/' + id) : '')
  window.scrollTo({
    top: section.getBoundingClientRect().top + window.pageYOffset,
  })
}

/* Инициализация полноэкранного режима конструктора */
function initFullScreen (containerEl) {
  containerEl.querySelectorAll('[data-toggle-fullscreen]').forEach(button => {
    button.addEventListener('click', evt => {
      const section = document.body.querySelector('[full-screen]')

      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        section.requestFullscreen();
      }
    })
  })
}

const sortFieldsByVariablesPositions = (fields, variablesIds) => {
  const collectSortWeights = (variablesIds) => {
    const sortWeights = {}
    let sortWeight = 1
    variablesIds.forEach(variableId => {
      sortWeights[variableId] = sortWeight++
    })
    return sortWeights
  }

  const sortWeights = collectSortWeights(variablesIds)

  fields.sort(function(a, b) {
    const aWeight = parseInt(sortWeights[a.dataset.fieldId]) || 100
    const bWeight = parseInt(sortWeights[b.dataset.fieldId]) || 100
    return aWeight < bWeight ? -1 : 1
  });

  const parent = fields[0].parentElement;
  fields.forEach(element => {
    parent.append(element)
  })
}

export default {
  init (containerEl) {
    initConstructor(containerEl)
  },
  open () {

  }
}
