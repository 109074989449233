const today = new Date()

export const defaultValues = {
    'company.name.short': 'АНО ДПО «Учебный центр»',
    'company.name.full': 'Автономная Некоммерческая Организация Дополнительного Профессионального образования «Учебный центр»',
    'director.position': 'директор',
    'director.fio': 'Петров Александр Владимирович',
    'number': '1',
    'date': today.toISOString().substring(0, 10),
    'site': 'site.ru',
    'sdo': 'sdo.site.ru',
    'company.address': 'г. Москва, ул. Ленина, д. 1',
    'print.office': 'ООО Печать',
    'city': 'г. Москва',
    'license': 'Лицензия на осуществление образовательной деятельности №___ от ______ выданная ______________, серия бланка ______, номер бланка _______',
    'license_pred_p': 'лицензии на осуществление образовательной деятельности №___ от ______, выданной ______________, серия бланка _____, номер бланка _____',
}
