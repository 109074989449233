type Params = FormData | Object | null

export default function (action: string, params: Params)
{
    let formData = new FormData()
    if (params instanceof FormData) {
        formData = params
    } else {
        if (params instanceof Object) {
            Object.entries(params).forEach(([key, value]) => {
                formData.append(key, value)
            });
        }
    }
    formData.set('action', action)

    return fetch('/ajax', {
        method: 'POST',
        body: formData
    })
        .then(response => response.json())
        .then(result => {
            if (result.status === 'success') {
                return result.payload
            }
            throw new Error(result.message);
        })
}
