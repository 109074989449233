import constructor from './constructor/'

/* Инициализация конструктора */
document.addEventListener('DOMContentLoaded', function _initConstructor () {
  document.removeEventListener('DOMContentLoaded', _initConstructor)

  const constructorEl = document.querySelector('.constructor')
  if (constructorEl) {
    constructor.init(constructorEl)
  }
})
